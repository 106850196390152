/* === Archivo Black - regular */
@font-face {
	font-family: 'Archivo Black';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//ArchivoBlack-Regular.eot");
	src: local('Archivo Black'),
		url("./../css/font//ArchivoBlack-Regular.eot") format("embedded-opentype"),
		url("./../css/font//ArchivoBlack-Regular.woff2") format("woff2"),
		url("./../css/font//ArchivoBlack-Regular.woff") format("woff"),
		url("./../css/font//ArchivoBlack-Regular.ttf") format("truetype"),
		url("./../css/font//ArchivoBlack-Regular.svg") format("svg");
}

/* === Noto Sans JP - regular */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Regular.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Regular.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Regular.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Regular.woff") format("woff"),
		url("./../css/font//NotoSansJp-Regular.svg") format("svg");
}

/* === Noto Sans JP - 500 */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Medium.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Medium.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Medium.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Medium.woff") format("woff"),
		url("./../css/font//NotoSansJp-Medium.svg") format("svg");
}

/* === Noto Sans JP - 700 */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Bold.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Bold.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Bold.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Bold.woff") format("woff"),
		url("./../css/font//NotoSansJp-Bold.svg") format("svg");
}
